import {isRegExp} from "@/lib/getVariableType";

if (!String.prototype.replaceAll) { // todo: extract
  String.prototype.replaceAll = function(pattern, replacement) {
    const patternIsRegExp = isRegExp(pattern)
    if (!patternIsRegExp) {
      pattern = pattern.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
      pattern = new RegExp(pattern, 'g')
    }
    return this.replace(pattern, replacement)
  }
}
