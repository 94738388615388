function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement("textarea")
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = "0"
  textArea.style.left = "0"
  textArea.style.position = "fixed"

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    const successful = document.execCommand('copy')
    if (!successful) throw 'document.execCommand("copy") did not succeed'
  } catch (err) {
    throw CopyTextToClipboardError(err, text)
  }

  document.body.removeChild(textArea);
}
export function copyTextToClipboard(text) { // https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript#answer-30810322
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(null, function(err) {
    throw new CopyTextToClipboardError(err, text)
  });
}

class CopyTextToClipboardError extends Error {
  constructor(err, text) {
    super(err.message ?? err)
    this.name = err.name ?? 'CopyTextToClipboardError'
    this.text = text
  }
}
