export const isString = (v) => { return typeof v === 'string' || v instanceof String }
export const  isNumber = (v) => { return typeof v === 'number' || v instanceof Number }
export const  isBoolean = (v) => { return typeof v === 'boolean' || v instanceof Boolean }
export const  isArray = (v) => { return v instanceof Array }
export const  isRegExp = (v) => { return v instanceof RegExp }
export const  isFunction = (v) => { return typeof v === 'function' || v instanceof Function }
export const  isObject = (v, strict) => {
  let assessment = typeof v === 'object'
  if (strict) {
    assessment = assessment && !isString(v)
    assessment = assessment && !isNumber(v)
    assessment = assessment && !isBoolean(v)
    assessment = assessment && !isArray(v)
    assessment = assessment && !isRegExp(v)
    assessment = assessment && !isFunction(v)
  }
  return assessment
}

export default function getVariableType(v) {
  let type = isString(v) ? 'String' : undefined
  if (!type) type = isNumber(v) ? 'vNumber.vue' : undefined
  if (!type) type = isBoolean(v) ? 'vBoolean' : undefined
  if (!type) type = isFunction(v) ? 'Function' : undefined
  if (!type) type = isRegExp(v) ? 'RegExp' : undefined
  if (!type) type = isArray(v) ? 'Array' : undefined
  if (!type) type = isObject(v) ? 'Object' : undefined
  return type
}
